/*
 *  Document   : app.js
 *  Author     : pixelcave
 *  Description: Main entry point
 *
 */
// Import required modules
import Alpine from "alpinejs";
import Clipboard from "@ryangjchandler/alpine-clipboard";
import "livewire-sortable";

import Template from "./modules/template";

Alpine.plugin(Clipboard);

window.Alpine = Alpine;
Alpine.start();

// App extends Template
export default class App extends Template {
    /*
     * Auto called when creating a new instance
     *
     */
    constructor() {
        super();
    }

    /*
     *  Here you can override or extend any function you want from Template class
     *  if you would like to change/extend/remove the default functionality.
     *
     *  This way it will be easier for you to update the module files if a new update
     *  is released since all your changes will be in here overriding the original ones.
     *
     *  Let's have a look at the _uiInit() function, the one that runs the first time
     *  we create an instance of Template class or App class which extends it. This function
     *  inits all vital functionality but you can change it to fit your own needs.
     *
     */

    /*
     * EXAMPLE #1 - Removing default functionality by making it empty
     *
     */

    //  _uiInit() {}

    /*
     * EXAMPLE #2 - Extending default functionality with additional code
     *
     */

    //  _uiInit() {
    //      // Call original function
    //      super._uiInit();
    //
    //      // Your extra JS code afterwards
    //  }

    /*
     * EXAMPLE #3 - Replacing default functionality by writing your own code
     *
     */

    //  _uiInit() {
    //      // Your own JS code without ever calling the original function's code
    //  }
}

// Create a new instance of App
window.One = new App();

// Loader
setTimeout(function () {
    $("#page-loader").addClass("animated fadeOut");
}, 1000);
setTimeout(function () {
    $("#page-loader").addClass("d-none");
}, 2000);

// Extension
One.helpersOnLoad(["jq-notify", "js-ckeditor", "jq-appear"]);

// Notify JS
window.addEventListener("notify", (event) => {
    if (event.detail.type == "info") {
        var alertType = "fa fa-info-circle me-1";
    } else if (event.detail.type == "warning") {
        var alertType = "fa fa-exclamation me-1";
    } else if (event.detail.type == "success") {
        var alertType = "fa fa-check me-1";
    } else {
        var alertType = "fa fa-times me-1";
    }
    One.helpers("jq-notify", {
        type: event.detail.type,
        icon: alertType,
        message: event.detail.title,
    });
});

// Sweetalert2
One.onLoad(
    class {
        static sweetAlert2() {
            let e = Swal.mixin({
                buttonsStyling: !1,
                target: "#page-container",
                customClass: {
                    confirmButton: "btn btn-alt-danger m-1",
                    cancelButton: "btn btn-alt-secondary m-1",
                    input: "form-control",
                },
            });
            window.addEventListener("swal:delete", (event) => {
                e.fire({
                    title: event.detail.title,
                    text: event.detail.text,
                    type: event.detail.type,
                    showCancelButton: true,
                    confirmButtonText: event.detail.button,
                }).then(function (result) {
                    if (result.value) {
                        window.livewire.emit("delete");
                    }
                });
            });

            window.addEventListener("swal:modal", (event) => {
                e.fire({
                    title: event.detail.title,
                    text: event.detail.text,
                    timer: event.detail.timer || 3000,
                });
            });

            window.addEventListener("swal:html", (event) => {
                e.fire({
                    title: event.detail.title,
                    html: event.detail.html,
                });
            });
        }

        static init() {
            this.sweetAlert2();
        }
    }.init()
);
