import "bootstrap";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Swiper from "swiper";
import { Pagination } from "swiper/modules";
import "swiper/css";
import simpleParallax from "simple-parallax-js";

const baseUrl = window.location.origin;
// plugins
/* aos */
AOS.init();
/* swiper */
const swiper = new Swiper(".swiper", {
    modules: [Pagination],
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});
/* simple parallax */
const image = document.getElementsByClassName("parallax");
new simpleParallax(image, {
    delay: 0,
    orientation: "down",
    scale: 1.5,
    overflow: true,
});
